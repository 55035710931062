import { Box, Flex, Image, useBreakpointValue } from "@chakra-ui/react";
import { useState } from "react";

const SmallIcon = ({ icon, linkText, h, big = false, customMarginTop = "0px", hoverShiftDown = false }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Dynamically calculate size based on breakpoint
  const iconSize = useBreakpointValue({
    base: h * 0.62, // Mobile size multiplier (0.65x)
    md: h, // PC size remains unaffected
  });

  return (
    <Box
      as="a"
      href={`https://${linkText}`}
      target="_blank"
      rel="noopener noreferrer"
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      margin="0px" // Remove all margin for mobile (set in parent for PC)
      marginTop={hoverShiftDown && isHovered ? "45px" : customMarginTop} // Move Patreon icon down on hover
      style={{
        transition: isHovered ? "margin 0.5s ease, transform 0.5s ease" : "none", // Smooth on hover, instant reset
        marginLeft: isHovered ? "15px" : "5px", // Smooth side movement for all icons
      }}
      transform={isHovered ? "scale(1.15)" : "scale(1)"} // Hover size increase
    >
      <Image
        src={`/icons/${icon}.png`}
        boxSize={`${big ? iconSize * 1.15 : iconSize}px`} // Subtle hover size increase for big icons
        borderRadius="50%"
        border="4px solid lightgray" // Doubled border size
        objectFit="cover"
        fallbackSrc="/icons/default.png" // Fallback for missing icons
        transition="transform 0.5s ease" // Smooth scaling animation
      />
    </Box>
  );
};

const Header = ({ head_height = 40 }) => {
  const baseSize = head_height * 2.5; // Default size for PC icons
  const patreonSize = baseSize * 1.3; // Proper size for Patreon on PC

  const gap = useBreakpointValue({
    base: "0px", // No gap on mobile
    md: "33px", // Default spacing on PC
  });

  return (
    <Flex
      as="header"
      position="fixed"
      top="0"
      left="0" // Ensure the header starts from the very left
      width="100%"
      backgroundColor="transparent" // No background
      zIndex="1000"
      padding="0px" // Remove all padding
      justifyContent="center" // Tightly grouped in the center
      alignItems="center"
      flexWrap="wrap"
      gap={gap} // Dynamic gap based on breakpoint
    >
      {/* Adjust sizes for all icons */}
      <SmallIcon icon="paw" linkText="furaffinity.net/user/tenkelly" h={baseSize} />
      <SmallIcon icon="e61" linkText="e621.net/users/1343337" h={baseSize} />
      <SmallIcon
        icon="patreon"
        linkText="www.patreon.com/c/tenkelly"
        h={patreonSize} // Separate mobile and PC sizes
        big={true}
        customMarginTop="35px" // Base position for Patreon icon
        hoverShiftDown={true} // Move Patreon icon down on hover
      />
      <SmallIcon icon="discord" linkText="linktr.ee/tenkellyart" h={baseSize} />
      <SmallIcon icon="telegram" linkText="t.me/tenkellyart" h={baseSize} />
    </Flex>
  );
};

export const MainContent = ({ children }) => {
  return (
    <Box marginTop="80px"> {/* Adjust to match the header height */}
      {children}
    </Box>
  );
};

export default Header;
