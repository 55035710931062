import { Box } from "@chakra-ui/react";
import { black, darkDarkGray, darkGray, gray } from "./functions/Colors";
import UseGetSize from "./functions/UseGetSize";

import styles from "./css/hexagon.css";
import Header from "./header";
import Body from "./body";

export const Home = () => {
  const { height, width } = UseGetSize();

  return (
    <Box w={width} h={height} position="relative" overflow="hidden" bg={black}>
      <Box
        className="main"
        position="absolute"
        left={-50}
        top={-50}
        w="125%"
        h="125%"
        zIndex={10}
      >
        <Box className="container">
          {Array.from({ length: Math.floor(width / 2) }).map((_, index) => (
            <div key={index} style={{ zIndex: 10, position: "relative" }}></div>
          ))}
        </Box>
      </Box>
      <Box
        w="100%"
        h="100%"
        position="absolute"
        zIndex={10}
        bgGradient={`linear-gradient(${darkDarkGray}, transparent 25%), linear-gradient(to right, ${darkDarkGray}, transparent 25%), linear-gradient(to left, ${darkDarkGray}, transparent 25%), linear-gradient(to top, ${darkDarkGray}, transparent 25%)`}
      ></Box>

      <Header />
      <Body />
    </Box>
  );
};

export default Home;
